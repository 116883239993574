import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {of} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {map, take} from 'rxjs/operators';

import {AppStateService, PlatformBrowserService} from '@core/modules';
import {UserService} from '@services/user/user.service';

@Injectable()
export class UserResolver  {
  constructor(
    private readonly appStateService: AppStateService,
    private readonly userService: UserService,
    private readonly platformBrowserService: PlatformBrowserService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<null> | Promise<null> | null {
    if (this.platformBrowserService.isBrowser && !this.appStateService.token) {
      return of(null);
    }
    return this.userService.verifyToken().pipe(
      map(() => null),
      take(1),
    );
  }

}
