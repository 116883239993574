import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

import {AppStateService} from '@core/modules';
import {AppRouterService} from '@core/services';

@Injectable()
export class AuthGuard  {

  constructor(
    private readonly appStateService: AppStateService,
    private readonly appRouter: AppRouterService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuth(state);
  }

  private checkAuth(state: RouterStateSnapshot): boolean {
    const isAuthenticated = this.appStateService.isAuthenticated;
    if (!isAuthenticated) {
      this.appRouter.navigateToLogin({ returnUrl: state.url });
      return false;
    }
    return true;
  }

}
